import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UsersService } from 'app/services/users.service';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'app/services/global.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    langs: string[] = [];
    public title: string;
    public dataTranslate: any;
    public userActive: any;
    public active: boolean;
    public fullName: string;
    public imgProfileUser: any;
    public cargo: string;
    public imgCheck: boolean;
    public dateNow; any;
    public menu: any = [];
    public typeUser: any;
    public newLang: any;
    public tipoUsuario: any;
    public equipo: string;
    public nombreEquipo: string;
    public logoEquipo: string;

    public dataTranslateMenu: any;
    public dataTranslateMenu2: any;
    public dataTranslateMenu3: any;
    public dataTranslateMenu4: any;
    public newMenu: any = [];
    public newMenu2: any = [];
    public newMenu3: any = [];
    public newMenu4: any = [];
    public icoDown1: any;
    public icoDown2: any;
    public icoDown3: any;
    public icoDown4: any;
    public icoDown5: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        public _userService: UsersService,
        private _cdref: ChangeDetectorRef,
        private titleService: Title,
        private _globalservices: GlobalService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.checkUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                });
            }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.icoDown1 = 'expand_more';
        this.icoDown2 = 'expand_more';
        this.icoDown3 = 'expand_more';
        this.icoDown4 = 'expand_more';
        this.icoDown5 = 'expand_more';

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // Menu principal
        this._globalservices.currentLanguaje.subscribe(newLang => {
            this.newLang = newLang;
        });

        this._globalservices.currentMenu.subscribe(newMenu => {
            this.newMenu = newMenu;
        });

        this._globalservices.currentMenu2.subscribe(newMenu2 => {
            this.newMenu2 = newMenu2;
            for(let item of this.newMenu2){
            }
        });

        this._globalservices.currentMenu3.subscribe(newMenu3 => {
            this.newMenu3 = newMenu3;
            for(let item of this.newMenu3){
            }
        });

        this._globalservices.currentMenu4.subscribe(newMenu4 => {
            this.newMenu4 = newMenu4;
            for(let item of this.newMenu4){
            }
        });

    }


    checkUser() {
        const userData = this._userService.getUserData();
        this.userActive = userData;
        if (this.userActive) {
            this.userActive = this.userActive.user[0];
            this.fullName = this.userActive.Nombre + ' ' + this.userActive.Apellido;
            this.imgProfileUser = this.userActive.Foto;
            this.tipoUsuario = this.userActive.Tipo_usuario;
            this.equipo = this.userActive.IdEquipo;
            this.nombreEquipo = this.userActive.NombreEquipo;
            this.logoEquipo = this.userActive.LogoEquipo;
            if (this.imgProfileUser === null || this.imgProfileUser === 'null' || this.imgProfileUser === '') {
                this.imgCheck = false;
                this.imgProfileUser = this.userActive.Nombre;
            } else {
                this.imgCheck = true;
            }
            if (this.tipoUsuario === '1') {
                this._translateService.get('MENU_USER_1')
                    .subscribe((resMenu: any) => {
                        this.dataTranslateMenu = resMenu;
                        this._globalservices.setMenu(this.dataTranslateMenu);
                    });
            }
            if (this.tipoUsuario === '2') {
                this._translateService.get('MENU_USER_2')
                    .subscribe((resMenu: any) => {
                        this.dataTranslateMenu = resMenu;
                        this._globalservices.setMenu(this.dataTranslateMenu);
                    });
                this._translateService.get('MENU_USER_2_2')
                    .subscribe((resMenu2: any) => {
                        this.dataTranslateMenu2 = resMenu2;
                        this._globalservices.setMenu2(this.dataTranslateMenu2);
                    });
                this._translateService.get('MENU_USER_2_3')
                    .subscribe((resMenu3: any) => {
                        this.dataTranslateMenu3 = resMenu3;
                        this._globalservices.setMenu3(this.dataTranslateMenu3);
                    });
                this._translateService.get('MENU_USER_2_4')
                    .subscribe((resMenu4: any) => {
                        this.dataTranslateMenu4 = resMenu4;
                        this._globalservices.setMenu4(this.dataTranslateMenu4);
                    });
            }
            if (this.tipoUsuario === '3') {
                this._translateService.get('MENU_USER_3')
                    .subscribe((resMenu: any) => {
                        this.dataTranslateMenu = resMenu;
                        this._globalservices.setMenu(this.dataTranslateMenu);
                    });
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    clickDown1() {
        if (this.icoDown1 === 'expand_less') {
            this.icoDown1 = 'expand_more';
        } else {
            this.icoDown1 = 'expand_less';
        }
    }

    clickDown2() {
        if (this.icoDown2 === 'expand_less') {
            this.icoDown2 = 'expand_more';
        } else {
            this.icoDown2 = 'expand_less';
        }
    }

    clickDown3() {
        if (this.icoDown3 === 'expand_less') {
            this.icoDown3 = 'expand_more';
        } else {
            this.icoDown3 = 'expand_less';
        }
    }

    clickDown4() {
        if (this.icoDown4 === 'expand_less') {
            this.icoDown4 = 'expand_more';
        } else {
            this.icoDown4 = 'expand_less';
        }
    }

    clickDown5() {
        if (this.icoDown5 === 'expand_less') {
            this.icoDown5 = 'expand_more';
        } else {
            this.icoDown5 = 'expand_less';
        }
    }


}
