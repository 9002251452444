import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle1Component } from 'app/layout/components/navbar/vertical/style-1/style-1.component';
import { AvatarModule } from 'ngx-avatar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatVideoModule } from 'mat-video';
import { MatMenuModule } from '@angular/material/menu';
import { ToastrModule } from 'ng6-toastr-notifications';
import { LottieAnimationViewModule } from 'ng-lottie';
import { RouterModule } from '@angular/router';
import { NgxCollapseModule } from 'ngx-collapse';

const routes = [
    {
      path: '',
      component: NavbarVerticalStyle1Component
    }
  ];
  
@NgModule({
    declarations: [
        NavbarVerticalStyle1Component
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatIconModule,
        AvatarModule,
        FuseSharedModule,
        FuseNavigationModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatVideoModule,
        FuseSharedModule,
        MatMenuModule,
        ToastrModule.forRoot(),
        LottieAnimationViewModule.forRoot(),
        NgxCollapseModule 
    ],
    exports     : [
        NavbarVerticalStyle1Component
    ]
})
export class NavbarVerticalStyle1Module
{
}
