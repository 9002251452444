import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  constructor(public wsService: WebsocketService) { }

  sendMessage( mensaje: string ) {

      const payload = {
        de: 'Fernando',
        cuerpo: mensaje
      };

      this.wsService.emit('mensaje', payload );

    }

    getMessages() {
      return this.wsService.receiveEvent('mensaje-nuevo');
    }

    updateNotification(descrip: string) {
      
      const payload = {
        de: 'Fernando',
        cuerpo: descrip
      };
      this.wsService.emit('update-notification', payload);
    }
  
    getUpdateNotification(){
      return this.wsService.receiveEvent('send-update-notification');
    }
}
