import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {LoginGuard} from './guards/login.guard';
import { UsersService } from './services/users.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./main/login/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/home/home/home.module').then(m => m.HomeModule)
  },
  // Administrador
  {
    path: 'administrator-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/configuracion/configuracion-admin/configuracion-admin.module').then(m => m.ConfiguracionAdminModule)
  },
  {
    path: 'players-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/gestion-jugadores/gestion/gestion.module').then(m => m.GestionModule)
  },
  {
    path: 'technical-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/gestion-cuerpo-tecnico/gestion/gestion.module').then(m => m.GestionModule)
  },
  // Equipos
  {
    path: 'register-calendar',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/register-calendar/register-calendar.module').then(m => m.RegisterCalendarModule)
  },
  {
    path: 'activity-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/detalle-actividad/registro/registro.module').then(m => m.RegistroModule)
  },
  {
    path: 'time-line',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/time-line/time-line.module').then(m => m.TimeLineModule)
  },
  {
    path: 'workplan',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/plan-trabajo/plan-trabajo.module').then(m => m.PlanTrabajoModule)
  },
  {
    path: 'fulfilment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/cumplimiento-actividades/cumplimiento-actividades.module').then(m => m.CumplimientoActividadesModule)
  },
  {
    path: 'start-test',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tests/test-inicio/test-inicio.module').then(m => m.TestInicioModule)
  },
  {
    path: 'test-disc',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tests/test-prueba-disc/test-prueba-disc.module').then(m => m.TestPruebaDiscModule)
  },
  {
    path: 'test-iadov',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tests/test-iadov/test-iadov.module').then(m => m.TestIADOVModule)
  },
  {
    path: 'update-weight',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/control-peso/actualizar-peso/actualizar-peso.module').then(m => m.ActualizarPesoModule)
  },
  {
    path: 'average-of-fat',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/control-peso/porcentaje-grasa/porcentaje-grasa.module').then(m => m.PorcentajeGrasaModule)
  },
  {
    path: 'effective-time',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tiempo-efectivo/tiempo-efectivo.module').then(m => m.TiempoEfectivoModule)
  },
  {
    path: 'test-PSE',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tests/test-PSE/test-pse.module').then(m => m.TestPSEModule)
  },
  {
    path: 'test-wellness',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/tests/test-bienestar/test-bienestar.module').then(m => m.TestBienestarModule)
  },
  {
    path: 'internal-load',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/cargas/carga-interna/carga-interna.module').then(m => m.CargaInternaModule)
  },
  {
    path: 'external-load',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/cargas/carga-externa/carga-externa.module').then(m => m.CargaExternaModule)
  },
  {
    path: 'team-players',
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/teams/gestion-jugadores/gestion-jugadores.module').then(m => m.GestionJugadoresModule)
  },
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'recover-password',
    loadChildren: () => import('./main/login/recuperar-clave/recuperar-clave.module').then(m => m.RecuperarClaveModule)
  },
  {
    path: 'update-password/:email',
    loadChildren: () => import('./main/login/update-password/update-password.module').then(m => m.UpdatePasswordModule)
  },
  {
    path: 'error-500',
    loadChildren: () => import('./main/errores/error-conexion/conexion/conexion.module').then(m => m.ConexionModule)
  },
  {
    path: '**',
    loadChildren: () => import('./main/errores/no-encontrado/no-encontrado.module').then(m => m.NoEncontradoModule)
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [UsersService, AuthGuard, LoginGuard]
})
export class AppRoutingModule { }
