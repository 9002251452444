import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'app/services/global.service';
import { UsersService } from 'app/services/users.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageUploaderOptions, FileQueueObject } from 'ngx-image-uploader';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { TechnicalServiceService } from 'app/services/technical-service.service';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss']
})
export class EditarComponent implements OnInit {
  public isLoading: boolean;
  public viewError: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  langs: string[] = [];
  public title: string;
  public dataTranslate: any;
  public dataTranslateMenu: any = [];
  public userActive: any;
  public active: boolean;
  public fullName: string;
  public imgProfileUser: any;
  public cargo: string;
  public imgCheck: boolean;
  public dateNow; any;
  public tipoUsuario: number;
  public equipo: string;
  registerForm: FormGroup;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  public file: any;
  public url: any;
  public dataTeam: any;
  public fechaNacimiento: any;
  public titleError: string;
  public mensajeError: string;

  public id: any;
  public nombre: string;
  public apellido: string;
  public edad: any;
  public nacimiento: any;
  public whatsapp: any;
  public email: any;
  public foto: any;
  public typeUser: any;
  public idTypeUser: number;
  public nombreEquipo: string;
  public logoEquipo: string;

  options: ImageUploaderOptions = {
    thumbnailHeight: 200,
    thumbnailWidth: 200,
    uploadUrl: 'https://api.gestrainer.com/upload-photo',
    allowedImageTypes: ['image/png', 'image/jpeg'],
    maxImageSize: 3
  };

  constructor(public dialog: MatDialog,
              public router: Router,
              public toast: ToastrManager,
              private _translateService: TranslateService,
              public _globalservices: GlobalService,
              public _userService: UsersService,
              private readonly _dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this._translateService.use(this._translateService.currentLang);
    this._translateService.get('UPDATE_DATA_USER')
      .subscribe((res: any) => {
        this.dataTranslate = res;
      });


    this.lottieConfig = {
      path: 'assets/images/logos/loading.json',
      autoplay: true,
      loop: true
    };

  }

  ngOnInit() {
    this.isLoading = false;
    this._globalservices.setLanguage(this._translateService.currentLang);
    this._translateService.use(this._translateService.currentLang);

   
    this.registerForm = new FormGroup({
      nombreThechnical: new FormControl('', [Validators.required]),
      apellidoThechnical: new FormControl('', [Validators.required]),
      edadThechnical: new FormControl('', [Validators.required]),
      nacimientoThechnical: new FormControl('', [Validators.required]),
      whatsappThechnical: new FormControl('', [Validators.required]),
      emailThechnical: new FormControl('', [Validators.required, Validators.email])
    });

    this.checkUser();

  }

  addEvent(event: any) {
    this.fechaNacimiento = moment(event.value).format('YYYY-MM-DD');
  }
  onUpload(file: FileQueueObject) {
    this.url = file.response['body'].url;
  }

  updateData() {
    if (this.url === '' || this.url === undefined || this.url === null) {
      this.url = this.foto;
    }
    if (this.fechaNacimiento === '' || this.fechaNacimiento === undefined || this.fechaNacimiento === null) {
      this.fechaNacimiento = moment(this.nacimiento).format('YYYY-MM-DD');
    }
    if (this.registerForm.value.nombreThechnical === null || this.registerForm.value.nombreThechnical === '' || this.registerForm.value.nombreThechnical === undefined) {
      this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
      this.mensajeError = this.dataTranslate.MESSAGE_ERROR_DATA_3;
      this.showWarning();
      return;
    }
    if (this.registerForm.value.apellidoThechnical === null || this.registerForm.value.apellidoThechnical === '' || this.registerForm.value.apellidoThechnical === undefined) {
      this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
      this.mensajeError = this.dataTranslate.MESSAGE_ERROR_DATA_4;
      this.showWarning();
      return;
    }
    if (this.registerForm.value.edadThechnical === null || this.registerForm.value.edadThechnical === '' || this.registerForm.value.edadThechnical === undefined) {
      this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
      this.mensajeError = this.dataTranslate.MESSAGE_ERROR_DATA_5;
      this.showWarning();
      return;
    }
    if (this.registerForm.value.whatsappThechnical === null || this.registerForm.value.whatsappThechnical === '' || this.registerForm.value.whatsappThechnical === undefined) {
      this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
      this.mensajeError = this.dataTranslate.MESSAGE_ERROR_DATA_7;
      this.showWarning();
      return;
    }
    if (this.registerForm.value.emailThechnical === null || this.registerForm.value.emailThechnical === '' || this.registerForm.value.emailThechnical === undefined) {
      this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
      this.mensajeError = this.dataTranslate.MESSAGE_ERROR_DATA_8;
      this.showWarning();
      return;
    }

    this.isLoading = true;

    this._userService.updateUser({IdUsuario: this.id, Nombre: this.registerForm.value.nombreThechnical, Apellido: this.registerForm.value.apellidoThechnical, 
      Edad: this.registerForm.value.edadThechnical, Fecha_nacimiento: this.fechaNacimiento, Whatsapp: this.registerForm.value.whatsappThechnical, 
      Email: this.registerForm.value.emailThechnical, Foto: this.url}).subscribe(
      respData => {
        this.isLoading = false;
        this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
        this.mensajeError = this.dataTranslate.MESSAGE_UPDATE_OK;
        this.showSuccess();
        this._dialogRef.close(respData);
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 405) {
          this.titleError = this.dataTranslate.TITLE_PAGE_HOME_1;
          this.mensajeError = this.dataTranslate.ERROR_MESSAGE_1;
          this.showError();
        }
        if (error.status === 400) {
          this.titleError = this.dataTranslate.ERROR_TOKEN_1;
          this.mensajeError = this.dataTranslate.ERROR_TOKEN_2;
          this.showError();
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        if (error.status === 401) {
          this.titleError = this.dataTranslate.ERROR_TOKEN_1;
          this.mensajeError = this.dataTranslate.ERROR_TOKEN_3;
          this.showError();
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        if (error.status === 403) {
          this.titleError = this.dataTranslate.ERROR_TOKEN_1;
          this.mensajeError = this.dataTranslate.ERROR_TOKEN_4;
          this.showError();
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        if (error.status === 500) {
          this.router.navigateByUrl('/error-500');
        }

      });

  }

  checkUser() {
    const userData = this._userService.getUserData();
    this.userActive = userData;
    if (this.userActive) {
        this.userActive = this.userActive.user[0];

        this.id = this.userActive.IdUsuario;
        this.nombre = this.userActive.Nombre;
        this.apellido = this.userActive.Apellido;
        this.edad = this.userActive.Edad;
        this.nacimiento = this.userActive.Fecha_nacimiento;
        this.whatsapp = this.userActive.Whatsapp;
        this.email = this.userActive.Email;
        this.foto = this.userActive.Foto;


        this.fullName = this.userActive.Nombre + ' ' + this.userActive.Apellido;
        this.imgProfileUser = this.userActive.Foto;
        this.tipoUsuario = this.userActive.Tipo_usuario;
        this.equipo = this.userActive.IdEquipo;
        this.nombreEquipo = this.userActive.NombreEquipo;
        this.logoEquipo = this.userActive.LogoEquipo;

        if (this.imgProfileUser === null || this.imgProfileUser === 'null' || this.imgProfileUser === '') {
            this.imgCheck = false;
            this.imgProfileUser = this.userActive.Nombre;
        } else {
            this.imgCheck = true;
        }
    }
}

  close() {
    this._dialogRef.close();
  }

  showError() {
    this.toast.errorToastr(this.titleError, this.mensajeError);
  }

  showSuccess() {
    this.toast.successToastr(this.mensajeError, this.titleError);
  }

  showWarning() {
    this.toast.warningToastr(this.mensajeError, this.titleError);
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }

}
