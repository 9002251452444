import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs/index';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private _url: string;
  private _secret_key: string;

  private LanguageSource = new BehaviorSubject<string>('es');
  currentLanguaje = this.LanguageSource.asObservable();

  private menuSource = new BehaviorSubject<any>('');
  currentMenu = this.menuSource.asObservable();
  
  private menuSource2 = new BehaviorSubject<any>('');
  currentMenu2 = this.menuSource2.asObservable();

  private menuSource3 = new BehaviorSubject<any>('');
  currentMenu3 = this.menuSource3.asObservable();

  
  private menuSource4 = new BehaviorSubject<any>('');
  currentMenu4 = this.menuSource4.asObservable();

  private countSource = new BehaviorSubject<any>('');
  currentCount = this.countSource.asObservable();

  constructor(private _router: Router,
    private _http: HttpClient,
    private _userService: UsersService) {
    this._url = environment.API_ENDPOINT;
    this._secret_key = environment.CLIENT_SECRET;
  }


  getUsersTeam(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this._userService.getUserToken().token);
    return this._http.get(`${this._url}player-list/${id}`, { headers: headers });
  }

  getMinSesion(id: number, nro: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this._userService.getUserToken().token);
    return this._http.get(`${this._url}get-total-min-sesion/${id}/${nro}`, { headers: headers });
  }

  getSesions(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this._userService.getUserToken().token);
    return this._http.get(`${this._url}get-sesions-number/${id}`, { headers: headers });
  }

  newComponent(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this._userService.getUserToken().token);
    return this._http.post(`${this._url}new-component`, params, { headers: headers });
  }
  
  public setLanguage(lag: string) {
      this.LanguageSource.next(lag);
  }

  public setMenu(menu: any) {
    this.menuSource.next(menu);
  }

  public setMenu2(menu: any) {
    this.menuSource2.next(menu);
  }

  public setMenu3(menu: any) {
    this.menuSource3.next(menu);
  }

  public setMenu4(menu: any) {
    this.menuSource4.next(menu);
  }

  public setCount(num: any) {
    this.countSource.next(num);
  }

}
