import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UsersService } from 'app/services/users.service';

@Injectable({
    providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

    constructor(private router: Router, private _userService: UsersService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = this._userService.getUserToken();

        let request = req;

    

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                
                if (err.status === 401) {
                    this.router.navigateByUrl('/login');
                }

                if (err.status === 500) {
                    this.router.navigateByUrl('error-500');
                }

                if (err.status === 0) {
                    this.router.navigateByUrl('error-500');
                }


                return throwError(err);

            })
        );
    }

}
