import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { AvatarModule } from 'ngx-avatar';
import { EditarComponent } from 'app/main/gestion-cuerpo-tecnico/modals/editar-datos/editar/editar.component';
import { MatDialogModule, MatFormFieldModule, MatSelectModule, MatStepperModule, MatListModule, MatTabsModule, MatTableModule, MatPaginatorModule, MatDatepickerModule, MatInputModule } from '@angular/material';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ImageUploaderModule } from 'ngx-image-uploader';
import {MatBadgeModule} from '@angular/material/badge';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
    declarations: [
        ToolbarComponent,
        EditarComponent
    ],
    entryComponents: [EditarComponent],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        AvatarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatStepperModule,
        FuseSharedModule,
        MatListModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatIconModule,
        LottieAnimationViewModule,
        MatDialogModule,
        MatInputModule,
        ImageUploaderModule,
        AvatarModule,
        MatDatepickerModule,
        AvatarModule,
        AngularFontAwesomeModule,
        MatBadgeModule,
        NgxSkeletonLoaderModule
        
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
