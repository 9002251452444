import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs/index';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  private _url: string;
  private _secret_key: string;
  
  constructor(private _router: Router,
              private _http: HttpClient,
              private _userService: UsersService) {
      this._url = environment.API_ENDPOINT;
      this._secret_key = environment.CLIENT_SECRET;
    }


    // Equipo
    getNotification(id: any, user: any): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', this._userService.getUserToken().token);
      return this._http.get(`${this._url}get-notification/${id}/${user}`, { headers: headers });
    }

    getNewNotification(id: any, user: any): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', this._userService.getUserToken().token);
      return this._http.get(`${this._url}get-new-notification/${id}/${user}`, { headers: headers });
    }

    getAllNotification(id: any, user: any): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', this._userService.getUserToken().token);
      return this._http.get(`${this._url}get-notification-all/${id}/${user}`, { headers: headers });
    }

    deleteNotification(id: number): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', this._userService.getUserToken().token);
      return this._http.get(`${this._url}delete-notification/${id}`, { headers: headers });
    }

    // Administrador
    getNotificationAdmin(): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', this._userService.getUserToken().token);
      return this._http.get(`${this._url}admin-get-notification`, { headers: headers });
    }
}
