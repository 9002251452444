import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { GlobalService } from 'app/services/global.service';
import { UsersService } from 'app/services/users.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { EditarComponent } from 'app/main/gestion-cuerpo-tecnico/modals/editar-datos/editar/editar.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { Router } from '@angular/router';
import Push from 'push.js';
import { RealTimeService } from 'app/services/real-time.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    public isLoading: boolean;
    public viewError: boolean;
    public noActivity: boolean;

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    langs: string[] = [];
    public title: string;
    public dataTranslate: any;
    public dataTranslateMenu: any = [];
    public userActive: any;
    public active: boolean;
    public fullName: string;
    public imgProfileUser: any;
    public cargo: string;
    public imgCheck: boolean;
    public dateNow; any;
    public typeUser: any;
    public tipoUsuario: any;
    public equipo: string;
    public titleError: string;
    public mensajeError: string;
    public id: any;
    public totalNotification: number;
    public dataNotification: any;
    public idTeam: any;
    public idTypeTeam: any;
    public nombreEquipo: string;
    public logoEquipo: string;
    public idUsuario: any;
    public idEquipo: any;
    public totalNewNotification: any;

    // Private
    private _unsubscribeAll: Subject<any>;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public toast: ToastrManager,
        public dialog: MatDialog,
        private _realTimeService: RealTimeService,
        public _globalservices: GlobalService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _notificacionesService: NotificacionesService,
        private _userServices: UsersService,
        private _router: Router,
        private _globalService: GlobalService
    ) {

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        // Add languages
        this.langs = this._translateService.getLangs();

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._translateService.get('TOOLBAR')
            .subscribe((res: any) => {
                this.dataTranslate = res;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.checkUser();
        this._globalservices.setLanguage(this._translateService.currentLang);
        this._translateService.use(this._translateService.currentLang);

        moment.locale(this._translateService.currentLang);
        this.dateNow = String(moment().format('dddd, '));
        this.dateNow = this.dateNow.substr(0, 1).toUpperCase() + this.dateNow.substr(1);
        this.dateNow = this.dateNow + String(moment().format('LL'));

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });


        // Actualizar data
        this._realTimeService.receiveActivity().subscribe(msg =>{
            if (Number(msg['notification'].idEquipo) === Number(this.idEquipo)) {
                this.checkUser();
                //Notificación
                Push.create(msg['notification'].from, {
                    body: msg['notification'].descrip,
                    icon: msg['notification'].photo,
                    timeout: 4000,
                    onClick: function () {
                        window.focus();
                        this.close();
                    }
                });
            }
        },(error) => {
            console.log(error);
        });
        
        this._globalService.currentCount.subscribe(newLang => {
            this.checkUser();
        });

    }

    editData() {
        const dialogRef = this.dialog.open(EditarComponent, {
            disableClose: false,
            width: '50%',
            height: 'auto',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.titleError = this.dataTranslate.MESSAGE_1;
                this.mensajeError = this.dataTranslate.MESSAGE_2;
                this.showWarning();
                this._userServices.logout();
            }
        });
    }

    // Administrador
    checkNotificationAdmin() {
        this.isLoading = true;
        this._notificacionesService.getNotificationAdmin().subscribe(
            respNoti => {
                this.isLoading = false;
                this.dataNotification = respNoti['notification'];
                this.totalNotification = respNoti['notification'].length;
            },
            (error) => {
                this.isLoading = false;
                if (error.status === 500) {
                    this._router.navigateByUrl('/error-500');
                }
            });
    }


    // Equipo
    checkNotification() {
        this.isLoading = true;
        this._notificacionesService.getNotification(this.idEquipo, this.idUsuario).subscribe(
            respNoti => {
                this.isLoading = false;
                this.dataNotification = respNoti['notification'];
                this.totalNotification = respNoti['notification'].length;
            },
            (error) => {
                this.isLoading = false;
                if (error.status === 500) {
                    this._router.navigateByUrl('/error-500');
                }
            });
    }

    countNotification() {
        this._notificacionesService.getNewNotification(this.idEquipo, this.idUsuario).subscribe(
            respNoti => {
                this.totalNewNotification = respNoti['notification'].length;
            },
            (error) => {
                this.isLoading = false;
                if (error.status === 500) {
                    this._router.navigateByUrl('/error-500');
                }
            });
    }



    checkUser() {
        const userData = this._userServices.getUserData();
        this.userActive = userData;
        if (this.userActive) {
            this.userActive = this.userActive.user[0];
            this.idEquipo = this.userActive.IdEquipo;
            this.idUsuario = this.userActive.IdUsuario;
            this.fullName = this.userActive.Nombre + ' ' + this.userActive.Apellido;
            this.imgProfileUser = this.userActive.Foto;
            this.tipoUsuario = this.userActive.Tipo_usuario;
            this.equipo = this.userActive.IdEquipo;
            this.nombreEquipo = this.userActive.NombreEquipo;
            this.logoEquipo = this.userActive.LogoEquipo;
            if (this.imgProfileUser === null || this.imgProfileUser === 'null' || this.imgProfileUser === '') {
                this.imgCheck = false;
                this.imgProfileUser = this.userActive.Nombre;
            } else {
                this.imgCheck = true;
            }

            if (this.tipoUsuario === '1') {
                //this.checkNotificationAdmin();
            }

            if (this.tipoUsuario === '2') {
                this.countNotification();
            }

            if (this.tipoUsuario === '3') {
                this.countNotification();
            }

        }
    }

    goTimeLine() {
        this._router.navigateByUrl('/time-line');
    }

    logOut() {
        this._userServices.logout();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang: string) {
        this._globalservices.setLanguage(lang);
        this._translateService.use(lang);
        this._translateService.get('TOOLBAR')
            .subscribe((res: any) => {
                this.dataTranslate = res;
            });

        if (this.tipoUsuario === '1') {
            this._translateService.get('MENU_USER_1')
                .subscribe((resMenu: any) => {
                    this.dataTranslateMenu = resMenu;
                    this._globalservices.setMenu(this.dataTranslateMenu);
                });
        }

        if (this.tipoUsuario === '2') {
            this._translateService.get('MENU_USER_2')
                .subscribe((resMenu: any) => {
                    this.dataTranslateMenu = resMenu;
                    this._globalservices.setMenu(this.dataTranslateMenu);
                });
        }

        if (this.tipoUsuario === '3') {
            this._translateService.get('MENU_USER_3')
                .subscribe((resMenu: any) => {
                    this.dataTranslateMenu = resMenu;
                    this._globalservices.setMenu(this.dataTranslateMenu);
                });
        }

        moment.locale(lang);
        this.dateNow = String(moment().format('dddd, '));
        this.dateNow = this.dateNow.substr(0, 1).toUpperCase() + this.dateNow.substr(1);
        this.dateNow = this.dateNow + String(moment().format('LL'));

    }

    showSuccess() {
        this.toast.successToastr(this.mensajeError, this.titleError);
    }

    showWarning() {
        this.toast.warningToastr(this.mensajeError, this.titleError);
    }

}
