
export const navigation = [
    {
        id       : 'sample',
                title    : 'Sample',
                translate: 'asdasd',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'sadasda',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
    }
];