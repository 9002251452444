import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public socketStatus = false;

  constructor(private socket: Socket) { this.checkStatus(); }

  
  checkStatus() {
    this.socket.on('conectado', () => {
      console.log('Conectado al servidor');
      this.socketStatus = true;
    })

    this.socket.on('desconectado', () => {
      console.log('Desconectado del servidor');
      this.socketStatus = false;
      this.socket.connect();
    })
  }

  emit( event: string, payload?: any, callback?: Function ){
    this.socket.emit( event, payload, callback);
  }

  receiveEvent( evento: string ) {
    return this.socket.fromEvent( evento );
  }

}
