import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs/index';
import { AES, SHA256 } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { AuthUser} from '../interfaces/auth.interface';
import { NewUserData, UpdateUserData } from 'app/interfaces/users.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private _url: string;
  private _secret_key: string;

  constructor(private _router: Router,
    private _http: HttpClient) {
    this._url = environment.API_ENDPOINT;
    this._secret_key = environment.CLIENT_SECRET;
  }

  isLoggedIn(): boolean {
    return this.getSecureStorage('isLogged') === 'true';
  }

  getUserData() {
    return JSON.parse(this.getSecureStorage('userData') || null);
  }

  getUserToken() {
    return JSON.parse(this.getSecureStorage('token') || null);
  }

  private _encrypt(value) {
    return AES.encrypt(value, this._secret_key).toString();
  }

  private _decrypt(key) {
    const bytes = AES.decrypt(key, this._secret_key) || false;
    try {
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return false;
    }
  }

  setSecureStorage(key: string, value) {
    localStorage.setItem(SHA256(key).toString(), this._encrypt(value));
  }

  getSecureStorage(key) {
    const value = localStorage.getItem(SHA256(key).toString());
    return (value) ? this._decrypt(value) : false;
  }

  
  removeSecureStorage(key) {
    localStorage.removeItem(SHA256(key).toString());
  }

  login(user: AuthUser, gettoken: string): Observable<any> {
    if (gettoken != 'false'){
        user.gettoken = gettoken;
    }
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this._url}login`, params, { headers: headers });
  }
  

  getPassword(email: any): Observable<any> {
    let params = JSON.stringify(email);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this._url}recover-password`, params, { headers: headers });
  }


  checkUser(email: any): Observable<any> {
    let params = JSON.stringify(email);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this._url}check-users`, params, { headers });
  }

  updatePassword(data: any): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this._url}update-password`, params, { headers });
  }


  updateSocket(id: any, socket: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Content-Type','Access-Control-Allow-Origin');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.get(`${this._url}update-socket-user/${id}/${socket}`, { headers });
  }

  getSocket(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Content-Type','Access-Control-Allow-Origin');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.get(`${this._url}get-socket-user/${id}`, { headers });
  }

  getUsers(type?: any, id?: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Content-Type','Access-Control-Allow-Origin');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.get(`${this._url}users/${type}/${id}`, { headers });
  }

  newUser(data: NewUserData): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.post(`${this._url}new-user`, params, { headers: headers });
  }

  updateUser(data: UpdateUserData): Observable<any> {
    let params = JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.post(`${this._url}update-user`, params, { headers: headers });
  }

  deleteUser(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Content-Type','Access-Control-Allow-Origin');
    headers = headers.append('Authorization', this.getUserToken().token);
    return this._http.get(`${this._url}delete-user/${id}`, { headers });
  }


  logout() {
    this.removeSecureStorage('userData');
    this.removeSecureStorage('token');
    this.removeSecureStorage('isLogged');
    this._router.navigate(['/login']);
  }

}
