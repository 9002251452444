import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class RealTimeService {

  constructor(private _webSocket: WebsocketService) { }

   //Control de peso
   sendWeightControl(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_weight: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-weight', new_weight);
  }
  
  receiveWeightControl() {
    return this._webSocket.receiveEvent('new-weight');
  }

  // Test PSE
  sendPSE(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_pse: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-pse', new_pse);
  }

  receivePSE() {
    return this._webSocket.receiveEvent('new-pse');
  }

  // Test Bienestar
  sendBienestar(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_bienestar: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-bienestar', new_bienestar);
  }

  receiveBienestar() {
    return this._webSocket.receiveEvent('new-bienestar');
  }

   // Test satisfacción
   sendIadov(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_test: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-test', new_test);
  }

  receiveIadov() {
    return this._webSocket.receiveEvent('new-test');
  }

   // Tiempo efectivo
   sendTime(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_time: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-time', new_time);
  }

  receiveTime() {
    return this._webSocket.receiveEvent('new-time');
  }

  // Registro actividad
  sendActivity(idEquipo: any, imgProfileUser: any, fullName: string, titulo: string, descrip: any) {
    const new_not: any = {
      idEquipo: idEquipo,
      photo: imgProfileUser,
      from: fullName,
      titulo: titulo,
      descrip: descrip,
    };
    this._webSocket.emit('send-notification', new_not);
  }

  receiveActivity() {
    return this._webSocket.receiveEvent('new-notification');
  }
  
}
